// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 获取案件日志
export const getCaseLogList = (params) => {
    return postRequest(`/busi/caseLog/getByAjid`, params)
}

// 编辑
export const addCaseLog = (params) => {
    return postRequest('/busi/caseLog/insert', params)
}

// 编辑
export const editCaseLog = (params) => {
    return postRequest('/busi/caseLog/update', params)
}

// 删除
export const deleteCaseLog = (params) => {
    return postRequest('/busi/caseLog/delByIds', params)
}