<style scoped>
  .time {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .content {
    padding-left: 5px;
    cursor: pointer;
    width: 180px;
    height: 30px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

<template>
  <div class="case-log">
    <!-- Drawer抽屉 -->
    <Drawer :title="title" v-model="visible" width="720" :mask-closable="false">
      <Row>
        <Col span="8">
        <Timeline>
          <TimelineItem>
            <p class="time">案件日志</p>
          </TimelineItem>
          <TimelineItem v-for="(item, i) in caseLogList">
            <p class="time" v-on:click="clickItem({i})" v-if="item.jrzt=='1'">{{ item.rq }}</p>
            <p class="time" v-on:click="clickItem({i})" v-if="item.jrzt=='0'">{{ item.rq }}(待)</p>
            <p class="content" v-on:click="clickItem({i})">{{ item.ajlog }}</p>
          </TimelineItem>
        </Timeline>
        </Col>
        <Col span="15">
        <Form ref="form" :model="form" :rules="formValidate" label-position="top">
          <Row :gutter="32">
            <Col span="12">
            <FormItem label="办理日期" prop="rq">
              <DatePicker type="date" v-model="form.rq" style="display: block" clearable></DatePicker>
            </FormItem>
            </Col>
            <Col span="12">
            <FormItem label="是否提醒" prop="tx">
              <i-switch v-model="form.tx" :true-value="'1'" :false-value="'0'"></i-switch>
            </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="12">
            <FormItem label="办理状态" prop="jrzt">
              <Select prefix="ios-cube-outline" v-model="form.jrzt">
                <Option v-for="(item, i) in this.$store.state.dict.blzt" :key="i" :value="item.value">{{ item.title }}
                </Option>
              </Select>
            </FormItem>
            </Col>
            <Col span="12">
            <FormItem label="办理人" prop="blr">
              <Input v-model="form.blr" autocomplete="off" :maxlength="20" />
            </FormItem>
            </Col>
          </Row>
          <Row :gutter="32">
            <Col span="24">
              <FormItem label="办案日志" prop="ajlog">
                <Input type="textarea" v-model="form.ajlog" show-word-limit :maxlength=200 :rows=3 clearable />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <div class="drawer-footer br">
              <Button :loading="submitLoading" @click="handleSubmit">保存</Button>
              <Button :loading="submitLoading" @click="remove" :disabled="form.id==''">删除</Button>
              <Button @click="visible = false">关闭</Button>
            </div>
          </Row>
        </Form>
        </Col>
      </Row>
    </Drawer>
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    getCaseLogList,
    addCaseLog,
    editCaseLog,
    deleteCaseLog
  } from "@/api/busi/case-manage/case-log";

  export default {
    name: "case-log",
    components: {},
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Object,
      },
    },
    data() {
      return {
        visible: this.value,
        title: "案件日志",
        passColor: "",
        submitLoading: false,
        maxHeight: 510,
        caseLogList: [],
        ajid: "",
        wtrxm: "",
        curIdx: -1,
        form: {
          id: "",
          telno: "",
          rq: "",
          jrzt: 0,
          blr: "",
          tx: "0",
          ajlog: "",
          ajid: "",
          wtrxm: "",
        },
        formValidate: {
          rq: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
          jrzt: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
          ajlog: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
        },
      };
    },
    methods: {
      init() {},
      setCurrentValue(value) {
        if (value === this.visible) {
          this.handleReset();
          return;
        }

        this.ajid = this.data.id;
        this.getDataList();

        this.visible = value;
      },
      getDataList() {
        this.loading = true;
        getCaseLogList({"ajid":this.ajid}).then(res => {
          this.loading = false;
          if (res.success) {
            this.caseLogList = res.result;
          }
        });
      },
      handleSubmit() {
        this.checkDate();
        this.form.ajid = this.ajid;
        this.form.wtrxm = this.wtrxm;
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.form.id == "") {
              addCaseLog(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.getDataList();
                  this.handleReset();
                }
              });
            } else {
              editCaseLog(this.form).then((res) => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.getDataList();
                  this.handleReset();
                }
              });
            } 

         }
        });
      },
      remove() {
        this.$Modal.confirm({
          title: "确认删除",
          // 记得确认修改此处
          content: "您确认要删除案件日志?",
          loading: true,
          onOk: () => {
            // 删除
            deleteCaseLog({
              ids: this.form.id
            }).then(res => {
              this.$Modal.remove();
              if (res.success) {
                this.$Message.success("操作成功");
                this.getDataList();
                this.handleReset();
              }
            });
          }
        });
      },
      clickItem(n) {
        this.$refs.form.resetFields();
        this.form.id = this.caseLogList[n.i].id;
        this.form.telno = this.caseLogList[n.i].telno;
        this.form.rq = this.caseLogList[n.i].rq;
        this.form.jrzt = this.caseLogList[n.i].jrzt;
        this.form.blr = this.caseLogList[n.i].blr;
        this.form.tx = this.caseLogList[n.i].tx;
        this.form.ajlog = this.caseLogList[n.i].ajlog;
        this.curIdx = n.i;
      },
      handleReset() {
        this.form.id = "";
        this.$refs.form.resetFields();
      },
      checkDate() {
        if (typeof this.form.rq == "object") {
          this.form.rq = this.format(this.form.rq, "yyyy-MM-dd");
        }
      },
    },
    watch: {
      value(val) {
        this.setCurrentValue(val);
      },
      visible(value) {
        this.$emit("input", value);
      },
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="less">
  // @import "../../../styles/table-common.less";
  .drawer-content {
    overflow: auto;
  }

  .drawer-content::-webkit-scrollbar {
    display: none;
  }

  .user-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .info-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      margin-right: 16px;
    }
  }

  .info-header {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 16px;
  }
</style>